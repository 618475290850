import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


interface ContentsProps {
  title: string,
  id: string,
  image?: {
    enable: string;
    disable?: string | undefined;
  },
  disable?: boolean,
  color?: string
}

// set the max and min height of the paper
const maxWidth: number = 120 // 450
const minHeight: number = 120

const StyledPaper = styled(Paper)(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: "10px",
  boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15);",
  maxWidth: maxWidth,
  minWidth: maxWidth,
  maxHeight: minHeight,
  minHeight: minHeight,

}))


export function Contents(props: ContentsProps) {
  return (
    <Grid
      item
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="24px"
    >
      <CardActionArea href={props.id} disableRipple disabled={props.disable} sx={{ display: 'flex' }}>
        <StyledPaper elevation={5} style={{ position: 'relative' }}>
          <img src={props.disable ? props.image.disable : props.image.enable} alt="SFA" width='72px' height='72px' />
        </StyledPaper>
      </CardActionArea>
      <Grid item>
        <Typography display="flex" justifyContent='center' alignItems='center' width='120px' fontSize="14px" color={props.color} sx={{ textTransform: 'uppercase' }}>{props.title}</Typography>
      </Grid>
    </Grid>
  )
}

export default React.memo(Contents)