import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import FooterBannerImage from '../images/frontImage.jpg';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import WorkPlaceImage from '../images/workplace.svg';



export default React.memo( function FooterBanner () {
    return (
        <Grid container justifyContent="center" flexDirection="column" width="100%" marginTop="20px">
            {/* <Grid item 
            // md={6}
            >
              <Container>
                <Card elevation={0}>
                    <CardMedia component="img" image={FooterBannerImage} sx={{objectFit: 'contain'}}/>
                </Card>
              </Container>
              </Grid> */}
            <Grid item display="flex" 
            // md={6}
            >
                <Container sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Typography fontSize="20px" fontWeight={700} sx={{display: 'flex', alignItems: 'center'}}color={(theme) => theme.palette.darkColors.black80} gutterBottom>
                        Join the Heineken Workplace Group!
                    </Typography>
                    <Typography 
                    // sx={{maxWidth: {lg: "900px", md: "517px"}}} 
                    textAlign="justify"
                    fontSize="16px" lineHeight="18px" color={(theme) => theme.palette.darkColors.black60} gutterBottom>
                        Workplace is Heineken's internal Facebook where you can find the SEM workplace group consisting of nearly 500 members. This channel is an important communication channel within SEM and is used to communicate relevant updates concerning the program, where we can celebrate victories, spread awareness of new features and provide explanations where required.
                    </Typography>
                    <Grid item>
                        <Button variant='contained' sx={{mr: 2, mt: 1}} href='https://heineken.workplace.com/groups/1528470227225537' target='_blank' startIcon={<img src={WorkPlaceImage} height="30px" style={{ marginLeft: '5px', marginRight: "5px" }}/>}>Join Now</Button>
                        {/* <Button variant='outlined' sx={{mt: {md: 1, sm: 1 , xs: 1}}}>EXPLORE ALL COMMUNICATION CHANNELS</Button> */}
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    )
})