import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import SearchBar from './SearchBar';
import StarBackground from '../images/Starmega.svg';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

export function Banner() {

    return (
        <Grid container sx={{backgroundColor: (theme) => theme.palette.primary.dark}}>    
            <Grid container sx={{padding: 10}}>
                <Grid item>
                    <Typography fontSize="40px" fontWeight="700" lineHeight="45.5px" color={(theme) => theme.palette.common.white}>
                        Welcome to SEM Wiki
                    </Typography>
                    <Typography fontSize="32px" lineHeight="36.48px" sx={{color: '#D2DDD4'}}>
                        Your source of information for everything related to SEM
                    </Typography>
                    <Typography fontSize="16px" lineHeight="18.24px" paddingTop="20px" paddingBottom="20px" sx={{color: '#D2DDD4'}}>
                    Learn everything about the SEM ecosystem and all the tools connected to it.<br />Improve your knowledge about how to use SEM and stay up to date with the latest news!
                    </Typography>
                </Grid>
                <Grid container>
                    <SearchBar placeholder="Search contents or Topics" border="1px solid #FFFFFF" minWidth="542px"/>
                </Grid>

                {/* Comment buttons until content has been added */}
                
                {/* <Grid item sx={{mt: 3}}>
                    <Button variant="contained" disableRipple sx={{mr: 3, mt: {xs: 2, sm: 0, md: 0}}}>Getting started with SEM</Button>
                    <Button variant="contained" disableRipple sx={{backgroundColor: (theme) => theme.palette.common.white , color: (theme) => theme.palette.primary.main, mt: {xs: 2, sm: 0, md: 0}}}>What's New?</Button>
                </Grid> */}
            </Grid>
            <img src={StarBackground} alt="Star Mega" style={{position: 'absolute', right: 0}}/>
        </Grid>
    )
}

export default React.memo(Banner)