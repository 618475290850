import React from 'react'
import Layout from '../../components/Layout'
import Typography from '@mui/material/Typography';
import { Helmet } from "react-helmet";
import Contents from '../../components/Contents'
import repositories from '../../repositories'
import Grid from '@mui/material/Grid';
import Banner from '../Banner';
import Body from '../Body';
import FooterBanner from '../FooterBanner';
import { theme } from '../../theme'


export default function DocumentLandingPage() {

  // remove the "@@sidebar" key if users go to this page using useEffect
  const key = "@@sidebar"
  React.useEffect(() => {
    sessionStorage.removeItem(key)
  }, [])

  return (
    // start with the layout component if you are going to create a new page
    <Layout location={typeof window !== 'undefined' ? location : null}>
      <Helmet title="SEM Wiki - Home Page" defer={false} /> {/* Helmet is the document page title */}
      <Grid container>
        <Banner /> {/* Banner of the page */}
      </Grid>
      <Grid container display="flex" justifyContent="center" sx={{background: '#F6F6F6'}}> {/* Document Grid */}
          <Grid item sx={{mb: 3, mt: 3}}>
              <Typography align="center" fontWeight="700" fontSize="16px" sx={{color: '#666666'}}>Documentation</Typography>
              <Typography align="center"fontSize="16px" sx={{color: '#666666'}}>Want to explore more how to use one tool? Read the manual.....</Typography>
          </Grid>
          <Grid container justifyContent="center" spacing={4} sx={{mb: 4}}>
            {repositories.map((repo) => (
              <Contents title={repo.title} key={repo.slug} id={repo.slug} image={repo.img} disable={repo.disable} color={theme.palette.darkColors.black60}/>
            ))}
          </Grid>
      </Grid>
        <Body />
        {/* <Divider /> */}
        {/* <FooterBanner /> */}
    </Layout>
  )
}

