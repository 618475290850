import React from 'react'
import Layout from '../Layout'
import Typography from '@mui/material/Typography';
import { Helmet } from "react-helmet";
import Contents from '../Contents'
import internal from '../../internal'
import Grid from '@mui/material/Grid';
import { navigate } from "gatsby";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import {makeStyles, createStyles} from '@mui/styles'
import Hidden from '@mui/material/Hidden'
import { theme } from '../../theme'

// create custom design attributes
// useStyles is a naming convention make use to use it if you are going to create a custom design attribute
const useStyles = makeStyles((theme:any) => createStyles({
  //pageTitle custom breakpoints
  // if screen is sm (small) or less than small (breakpoints.down) then apply this attributes
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: "5vw",
      paddingTop: "5px"
    }
  },
  //pageDescription custom breakpoints
  // if screen is medium or less than md then justify the text
  pageDescription: {
    [theme.breakpoints.down('md')]: {
      textAlign: "justify"
    }
  },
  illustration: {
    width: "400px",
    [theme.breakpoints.down('lg')]:{
      height: "400px",
    },
    [theme.breakpoints.up('xl')]:{
      height:  "688px",
      width:  "720px",
    }
  }

}))


/* 
  Another way of customizing a component is using styled component see code below.
  name your styled component: ex. StyledSearchBar
  reference a component using the styled(): styled(Paper)
  if you have a global custom theme you can you the theme

  (({theme}:any) => {
    ..css attributes
  })
*/
const StyledContainer = styled("div")(({theme}: any) => ({
  [theme.breakpoints.down('md')]: {
    paddingRight: "5vw"
  }
}))



export default function InternalPage() {
  // create the searchRef which would be the html input 
  const searchRef = React.useRef<HTMLInputElement>();
  // the search function event
  const search = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();
    // navigate to the /search directory
    if (searchRef.current.value.length > 0) {
      navigate("/search", {
        state: { searchQuery: searchRef.current && searchRef.current.value },
      });
    }
  };

  // reference the useStyles here to use it.
  const classes = useStyles()

  // remove the "@@sidebar" key if users go to this page using useEffect
  const key = "@@sidebar"
  React.useEffect(() => {
    sessionStorage.removeItem(key)
  },[])

  return (
      // start with the layout component if you are going to create a new page
        <Layout location={typeof window !== 'undefined' ? location : null}>
        <Helmet title="Home | Internal SEM Wiki" defer={false}/>
        { /* Create a grid container to hold all sub containers */ }
        <Grid
        container
        // direction="column"
        sx={{height: "100vh", marginTop: "1vw", paddingTop: "5vw", paddingLeft: "5vw", background: (theme) => theme.palette.primary.dark}}
        >
          <Grid
          xs={12}
          md={7}
          xl={6}
          item
          >
            {/* Title of website */}
            <Typography sx={{
              fontSize: "80px",
              lineHeight: "82px",
            }} color='primary.light' align='left'>
              Internal SEM Wiki
            </Typography>
            {/* use Typography if you are working with texts. sx prop is a shortcut for defining custom style that has access to the theme*/}
            <Typography sx={{
              fontSize:  "14px",
              lineHeight: "15.96px",
              pb: 3,
              maxWidth: '725px',
              color: (theme) => (theme.palette.common.white),
              pr: {
                xs: '5vw'
              }
            }} variant="body1" align="left">
              Home of SEM related documentation that will guide SEM Key-Users, the Global Team and New Joiners in understanding all features and functionalities of all SEM solutions. Feel free to check out all relevant documentations to you that can serve as the starting point for your training materials.
            </Typography>
            {/* hold the custom search bar using the custom container*/}
            <StyledContainer>
              {/* SearchBar in the page*/}
              <Paper
              component="form"
              onSubmit={(e: any) => search(e)}
              sx={{
                backgroundColor: '#fff',
                border: "1px solid #008200",
                boxShadow: "none",
                maxWidth: "700px",
                paddingLeft: (theme) => (theme.spacing(2)),
                display: "flex",
              }}
              >
                {/* InputBase is where the user will input their queries*/}
                <InputBase style={{
                  fontFamily: "Heineken Core",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  color: "#999999",
                }} fullWidth inputRef={searchRef} inputProps={{spellCheck: "false"}}/>
                {/* use IconButton to make your icon clickable*/}
                <IconButton>
                  <SearchIcon style={{color: "#008200"}}/>
                </IconButton>
              </Paper>
            </StyledContainer>
            <Grid
          item
          >
            {/* Documentation title*/}
            <Typography
            sx={{
              fontSize: "24px",
              lineHeight: "24.58px",
              pt: 5,
              pb: 3,
              color: (theme:any) => (theme.palette.primary.light)
            }}
            >
              Documentations
           </Typography>
          </Grid>
          <Grid
          container
          spacing={2}
          > 
          {/* Map all the repositories in the repositories.ts and use the Contents component to create different grid box*/}

            {internal.map((repo) => (
                <Contents title={repo.title} id={repo.slug} key={repo.slug} image={repo.img} color={theme.palette.common.white}/>
              ))}
          </Grid>
          {/* <Grid 
          item
          sx={{pl: "4vw"}}
        >
            <Footer />
        </Grid> */}
          </Grid>
          {/* Hide the image if the screen size is medium down*/}
          <Grid
          item
          md={2}
          >
                <Hidden mdDown>
                  <img src="../../illustration.svg" alt="illustration" className={classes.illustration}/>
                </Hidden>
          </Grid>
        </Grid>
        <Grid 
          container
          sx={{pl: "4vw"}}
        >
            {/* <Footer /> */}
        </Grid>
    </Layout>
  )
}
