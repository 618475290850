import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import VerifiedIcon from '@mui/icons-material/Verified';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Container from '@mui/material/Container';
import rightarticle from '../rightarticle';
import Divider from '@mui/material/Divider';
import StarIcon from '@mui/icons-material/Star';
import VideoCarouselSwiper from './VideoCarouselSwiper';
import { CallAzureStorage } from '../utils/AzureStorageApiCall';
import { BlobClient, BlobItem, Metadata } from '@azure/storage-blob';
import { blobServiceClient, storageAccount, containerClient, sasKey } from '../utils/AzureStorageApiCall';
import moment from 'moment';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Modal from '@mui/material/Modal'
import FooterBanner from './FooterBanner';
import TSAIcon from '../images/icons/tsaicon.svg';
import Button from '@mui/material/Button';

export type MetaDataProps = {
    category: string,
    thumbnail: string,
    title: string,
    views: string
}

export type BlobStorageProps = {
    name: string,
    metadata: MetaDataProps,
    url: string,
    properties: {
        createdOn: string
    }
}

export function Body() {

    const [blobVideos, setBlobVideos] = React.useState<BlobItem[]>([])
    const [tsaVideos, setTsaVideos] = React.useState<BlobItem[]>([])
    const azureStorageUrl = `https://${process.env.GATSBY_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net/${process.env.GATSBY_AZURE_CONTAINER_NAME}/`

    const [playVideo, setPlayVideo] = React.useState(false);
    const [videoName, setVideoName] = React.useState<string>()
    const [videoProperties, setVideoProperties] = React.useState<BlobItem>()
    const videoRef = React.useRef<HTMLVideoElement>();

    const handlePlayVideo = (videos: BlobItem) => {
        setPlayVideo(true)
        setVideoName(videos.name)
        setVideoProperties(videos)
    }

    const handleCloseVideo = () => {
        setPlayVideo(false)
        videoRef.current.pause()
    }

    React.useEffect(() => {
        async function fetchData() {
            const blobData = await CallAzureStorage()
            setBlobVideos(blobData.videos)
            setTsaVideos(blobData.tsa)
        }
        fetchData()
    }, [])

    const timerFrames = {
        time: 30,
        trigger: false
    }


    async function handleTimeUpdate(video: React.SyntheticEvent<HTMLVideoElement>, name?: string, title?: string, thumbnail?: string, category?: string, views?: string) {
        let roundTime = Math.floor(video.currentTarget.currentTime)
        if (roundTime !== 0 && roundTime % timerFrames.time === 0 && !timerFrames.trigger) {
            let currentView = parseInt(views, 10) + 1;
            timerFrames.trigger = true
            const getBlob = containerClient.getBlobClient(name)
            await getBlob.setMetadata({
                title: title,
                thumbnail: thumbnail,
                category: category,
                views: `${currentView}`
            });

            await getBlob.setHTTPHeaders({
                blobCacheControl: 'no-cache'
            })
        }
    }

    return (
        // Body Container
        <Grid container display='flex' justifyContent="space-between" sx={{ padding: "30px 30px 30px 50px"}}>
            {/* Body Left side*/}
            <Grid item md={7} sm={12} xs={12}>
                <Typography sx={{ verticalAlign: 'middle', display: 'inline-flex', pb: 1 }} fontWeight="700" fontSize="18px">
                    <VerifiedIcon sx={{ mr: 1, color: '#FF2B00' }} />
                    Introduction to SEM
                </Typography>
                {blobVideos.slice(0, 1).map((video, index: number) => (
                    <Grid key={index}>
                        <Card elevation={5}>
                            <CardActionArea sx={{ objectFit: "cover", aspectRatio: "16/9" }} onClick={e => handlePlayVideo(video)}>
                                <Box sx={{ backgroundColor: 'rgba(0,0,0,0.5)', width: "100%", height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute' }}>
                                    <PlayCircleOutlineIcon sx={{ color: theme => theme.palette.common.white, width: "130px", height: "130px" }} />
                                </Box>
                                <CardMedia component="video" poster={video.metadata?.thumbnail + `${sasKey}`} sx={{ aspectRatio: "16/9", objectFit: 'cover' }} onTimeUpdate={event => handleTimeUpdate(event, video.name, video.metadata.title, video.metadata.thumbnail, video.metadata.category, video.metadata.views)} />
                            </CardActionArea>
                        </Card>
                        <Box height="15px" />
                        <Typography fontSize="18px" fontWeight={700} lineHeight="20px" color={(theme) => theme.palette.grey[100]}>
                            {video.metadata?.title}
                        </Typography>
                        <Typography fontSize="16px" lineHeight="20px" color={(theme) => theme.palette.darkColors.black100} marginTop="5px">
                            A digital tool that enables Our Sales Force with uniquely developed features to make smarter and faster decisions supported by actions and insights.
                        </Typography>
                        <Typography fontSize="13px" color={(theme) => theme.palette.grey[400]} >
                            {`${video.metadata?.views} views • ${moment(video.properties.createdOn).fromNow()}`}
                        </Typography>
                    </Grid>
                ))}

                <Divider sx={{ marginTop: "32px" }} />

                <Box height="30px" />

                <Typography fontSize="16px" fontWeight="700" color={(theme) => theme.palette.darkColors.black60} sx={{ verticalAlign: 'middle', display: 'inline-flex', pb: 0 }} marginBottom="0">
                    <StarIcon sx={{ color: '#FECB00', mr: 0.5 }} />
                    Skill Pills
                </Typography>
                <Typography fontWeight={500} fontSize="16px" lineHeight="20px" color={(theme) => theme.palette.darkColors.black100} marginLeft="28px" marginTop="5px" marginBottom="10px">
                    Useful tools and knowledge materials that can help Our Sales Reps to Excellent Execution.
                </Typography>

                {blobVideos.slice(1, 2).map((video, index: number) => (
                    <Grid key={index} display="flex" flexDirection="row" position="relative" marginBottom="16px">
                        <Card elevation={0} sx={{ width: "70%", borderRadius: 0}}>
                            <CardActionArea sx={{ objectFit: "cover", aspectRatio: "16/9" }} onClick={e => handlePlayVideo(video)}>
                                <Box sx={{ backgroundColor: 'rgba(0,0,0,0.5)', width: "100%", height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute' }}>
                                    <PlayCircleOutlineIcon sx={{ color: theme => theme.palette.common.white, width: "85.98px", height: "85.98px" }} />
                                </Box>
                                <CardMedia component="video" poster={video.metadata?.thumbnail + `${sasKey}`} sx={{ aspectRatio: "16/9", objectFit: 'cover' }} onTimeUpdate={event => handleTimeUpdate(event, video.name, video.metadata.title, video.metadata.thumbnail, video.metadata.category, video.metadata.views)} />
                            </CardActionArea>
                        </Card>
                        <Box width="30%" display="flex" flexDirection="column" sx={{ alignItems: 'stretch', backgroundColor: '#205527' , justifyContent: 'center', padding: '20px'}}>
                            <Typography fontSize="18px" fontWeight={700} lineHeight="20.52px" color="#FFFFFF">
                                {video.metadata?.title}
                            </Typography>
                            <Typography fontSize="13px" color="#A6BBA9">
                                {`${video.metadata?.views} views • ${moment(video.properties.createdOn).fromNow()}`}
                            </Typography>
                        </Box>
                    </Grid>
                ))}

                <VideoCarouselSwiper blobVideos={blobVideos} storageUrl={azureStorageUrl} sasKey={sasKey} timeUpdate={handleTimeUpdate} sliceNumber={2} slidesNumber={4} prevButtonClass="my-swiper-button-prev-1" nextButtonClass="my-swiper-button-next-1" />

                <Box height="48px"/>

                <Divider sx={{ marginBottom: "35px" }} />

                <Grid display="flex" flexDirection="row">
                    <img src={TSAIcon} width="16.55px" height="19.97px" style={{ marginRight: '7.45px' }} />
                    <Typography fontSize="16px" fontWeight="700" color={(theme) => theme.palette.darkColors.black60} sx={{ verticalAlign: 'middle', display: 'inline-flex', pb: 0.5 }} >
                        Targeted Sales Activity
                    </Typography>
                </Grid>
                <Typography fontWeight={500} fontSize="16px" lineHeight="20px" color={(theme) => theme.palette.darkColors.black100} marginLeft="24px" marginBottom="10px">
                    Success testimonials from our OpCos.
                </Typography>
                <VideoCarouselSwiper blobVideos={tsaVideos} storageUrl={azureStorageUrl} sasKey={sasKey} timeUpdate={handleTimeUpdate} sliceNumber={0} slidesNumber={3} prevButtonClass="my-swiper-button-prev-2" nextButtonClass="my-swiper-button-next-2" />

            </Grid>
            {/* Body Right side */}
            <Grid item justifyContent="flex-end" md={4} sm={12} sx={{ pt: "30px" }}>
                {
                    rightarticle.map((article, index) => (
                        <Grid key={index} container sx={{ padding: 1, flexWrap: 'wrap' }}>
                            <Card elevation={0}>
                                <CardActionArea href={article.ref}>
                                    <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: {md: 'center'} }}>
                                        <Grid item>
                                            <Box sx={{ height: "60px", width: "60px", margin: "0 10px 0 15px", borderRadius: "80px", border: "1px solid #E7E7E7", verticalAlign: "middle", display: "inline-flex", float: 'left', justifyContent: 'center', alignItems: 'center' }} >
                                                <img src={article.gif} alt={article.title} width="60px" />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={7} lg={8} sx={{ flex: 1, marginLeft: 1 }}>
                                            <Typography fontWeight="700" color={(theme) => theme.palette.primary.main} sx={{ mt: 1, textAlign: {md: 'center'} }}>
                                                {article.title}
                                            </Typography>
                                            <Typography fontSize="13px" gutterBottom sx={{textAlign: {md: 'justify'}}}>
                                                {article.content}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))
                }
                <Grid item sx={{ pt: 1 }}>
                    <Container sx={{ border: "1px solid #DBDBDB", padding: 3, borderRadius: "7px" }}>
                        <Box sx={{ padding: 1 }}>
                            <Typography fontSize="16px" fontWeight="800" gutterBottom color={(theme) => theme.palette.primary.main}>SUPPORT</Typography>
                        </Box>
                        <Box sx={{ padding: 1 }}>
                            <Typography fontSize="13px" fontWeight="800" gutterBottom color={(theme) => theme.palette.darkColors.black60}>QUESTIONS?</Typography>
                            <Typography fontSize="14px" gutterBottom color={(theme) => theme.palette.darkColors.black60}>All questions about SEM that are not Incidents or Change Requests should be asked via an RFI in ServiceNow.</Typography>
                        </Box>
                        <Box sx={{ padding: 1 }}>
                            <Typography fontSize="13px" fontWeight="800" gutterBottom color={(theme) => theme.palette.darkColors.black60}>ESCALATION</Typography>
                            <Typography fontSize="14px" gutterBottom color={(theme) => theme.palette.darkColors.black60}>If a ticket that has been logged in ServiceNow has not been answered or resolved yet by the Support Team, you can file an escalation through the product owner.</Typography>
                        </Box>
                        <Box sx={{ padding: 1 }}>
                            <Typography fontSize="13px" fontWeight="800" gutterBottom color={(theme) => theme.palette.darkColors.black60}>INCIDENTS & CHANGES</Typography>
                            <Typography fontSize="14px" gutterBottom color={(theme) => theme.palette.darkColors.black60}>Application issues should be logged as Incidents in ServiceNow, using the INC template (found on SharePoint). Change Requests also need to be logged in ServiceNow.​</Typography>
                        </Box>
                        <Button variant="contained" size="small" href="https://nextgen.service-now.com/it?id=sc_category&sys_id=b927de80db90ccd02430dbe6f4961909&catalog_id=-1&spa=1" target="_blank">RFI in ServiceNow</Button>
                      </Container>
                  </Grid>
                  <FooterBanner/>
            </Grid>
            <Modal open={playVideo} onClose={handleCloseVideo} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid sx={{ maxHeight: "70%", aspectRatio: "16/9" }}>
                    <CardMedia component="video" ref={videoRef} src={azureStorageUrl + videoName + `${sasKey}`} controls={playVideo} sx={{ aspectRatio: "16/9" }} autoPlay={true} onTimeUpdate={event => handleTimeUpdate(event, videoName, videoProperties.metadata?.title, videoProperties.metadata?.thumbnail, videoProperties.metadata?.category, videoProperties.metadata?.views)} />
                </Grid>
            </Modal>
        </Grid>
    )

}

export default React.memo(Body)
