const { BlobServiceClient, AnonymousCredential } = require("@azure/storage-blob");

export const azureAnonCredential = new AnonymousCredential();

export const sasKey = process.env.GATSBY_AZURE_SAS_KEY
export const storageAccount = `https://${process.env.GATSBY_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net${sasKey}`


export const blobServiceClient = new BlobServiceClient(
    storageAccount,
    azureAnonCredential
)



export const containerClient = blobServiceClient.getContainerClient(process.env.GATSBY_AZURE_CONTAINER_NAME)


export async function CallAzureStorage () {
    const videosBlobItems = [];
    const tsaBlobItems = [];


    const blobs = containerClient.listBlobsFlat({ includeMetadata: true, prefix: "videos/" });
    for await (const blob of blobs) {
       if (blob.name.startsWith("videos/tsa/")) {
          tsaBlobItems.push(blob);
       } else {
          videosBlobItems.push(blob);
       }
    }
    

    return {
       videos: videosBlobItems,
       tsa: tsaBlobItems
    };

}

