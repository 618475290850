import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Autoplay } from 'swiper';
import { BlobItem } from '@azure/storage-blob';
import moment from 'moment';
import {
    CardMedia,
    Card,
    Typography,
    Grid,
    IconButton,
    CardActionArea,
    Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import 'swiper/css';
import 'swiper/css/pagination';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Modal from '@mui/material/Modal';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const StyledSwiper = styled(Swiper)(() => ({
    '& .swiper-pagination-bullet': {
        backgroundColor: '#205527',
    },
    '& .swiper-pagination': {
        position: 'unset',
        padding: '16px 0px 0px',
        gap: '8px',
    },
}));

interface VideoCarouselSwiperProps {
    blobVideos: BlobItem[];
    storageUrl: string;
    sasKey: string;
    sliceNumber: number;
    slidesNumber: number;
    nextButtonClass: string;
    prevButtonClass: string;
    timeUpdate?: (
        video: React.SyntheticEvent<HTMLVideoElement>,
        name?: string,
        title?: string,
        thumbnail?: string,
        category?: string,
        views?: string
    ) => void;
}

const VideoCarouselSwiper: React.FC<VideoCarouselSwiperProps> = React.memo(
    ({
        blobVideos,
        storageUrl,
        sasKey,
        sliceNumber,
        slidesNumber,
        nextButtonClass,
        prevButtonClass,
        timeUpdate,
    }) => {
        const [videoState, setVideoState] = useState<{
            playVideo: boolean;
            videoName: string | undefined;
            videoProperties: BlobItem | undefined;
        }>({
            playVideo: false,
            videoName: undefined,
            videoProperties: undefined,
        });

        const videoRef = useRef<HTMLVideoElement>(null);

        const handlePlayVideo = (videos: BlobItem) => {
            setVideoState((prevState) => ({
                ...prevState,
                playVideo: true,
                videoName: videos.name,
                videoProperties: videos,
            }));
        };

        const handleCloseVideo = () => {
            setVideoState((prevState) => ({
                ...prevState,
                playVideo: false,
            }));
            if (videoRef.current) {
                videoRef.current.pause();
            }
        };

        const { playVideo, videoName, videoProperties } = videoState;

        const isNavigationVisible = slidesNumber === 3 ? blobVideos.length > 3 : slidesNumber === 4 ? blobVideos.length > 6 : true;

        return (
            <>
                <Grid container flexDirection="row" justifyContent="space-between" alignContent="center">
                    <Grid container className={prevButtonClass} width="5%" position="relative">
                        {isNavigationVisible && (
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: slidesNumber === 4 ? '29%' : '37%',
                                    transform: 'translateY(-50%)',
                                }}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        )}
                    </Grid>

                    <Box width={isNavigationVisible ? '90%' : '100%'} padding="0" margin="0">
                        <StyledSwiper
                            modules={[Pagination, Mousewheel, Autoplay, Navigation]}
                            pagination={{ clickable: true }}
                            navigation={{
                                nextEl: `.${nextButtonClass}`,
                                prevEl: `.${prevButtonClass}`,
                            }}
                            autoplay={false}
                            preventClicks={true}
                            breakpoints={{
                                1000: {
                                    direction: 'horizontal',
                                    slidesPerView: slidesNumber,
                                    spaceBetween: 15,
                                },
                                280: {
                                    direction: 'horizontal',
                                    slidesPerView: 1,
                                },
                            }}
                        >
                            {blobVideos.slice(sliceNumber).map((videos, index) => (
                                <SwiperSlide key={index}>
                                    <Card>
                                        <CardActionArea
                                            sx={{ objectFit: 'cover', aspectRatio: '16/9' }}
                                            onClick={(e) => handlePlayVideo(videos)}
                                        >
                                            <Box
                                                sx={{
                                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                }}
                                            >
                                                <PlayCircleOutlineIcon sx={{ color: (theme) => theme.palette.common.white, width: '41.6px', height: '41.6px' }} />
                                            </Box>
                                            <CardMedia component="video" poster={videos.metadata?.thumbnail + `${sasKey}`} sx={{ aspectRatio: '16/9', objectFit: 'cover' }} />
                                        </CardActionArea>
                                    </Card>
                                    <Grid item>
                                        <Typography fontSize="12px" color={(theme) => theme.palette.grey[400]} sx={{ pt: 0.5 }}>
                                            {videos.metadata?.category}
                                        </Typography>
                                        <Typography fontSize="14px" lineHeight="16px" color={(theme) => theme.palette.grey[100]}>
                                            {videos.metadata?.title}
                                        </Typography>
                                        <Typography fontSize="12px" color={(theme) => theme.palette.grey[400]}>
                                            {`${videos.metadata?.views} views • ${moment(videos.properties.createdOn).fromNow()}`}
                                        </Typography>
                                    </Grid>
                                </SwiperSlide>
                            ))}
                        </StyledSwiper>
                    </Box>

                    <Grid container className={nextButtonClass} width="5%" justifyContent="flex-end" position="relative">
                        {isNavigationVisible && (
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    bottom: slidesNumber === 4 ? '71%' : '63%',
                                    transform: 'translateY(50%)',
                                }}
                            >
                                <ChevronRightIcon />
                            </IconButton>
                        )}
                    </Grid>

                    <Modal open={playVideo} onClose={handleCloseVideo} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid sx={{ maxHeight: '70%', aspectRatio: '16/9' }}>
                            <CardMedia
                                component="video"
                                ref={videoRef}
                                src={storageUrl + videoName + `${sasKey}`}
                                controls={playVideo}
                                sx={{ aspectRatio: '16/9' }}
                                autoPlay={true}
                                onTimeUpdate={(event) =>
                                    timeUpdate && timeUpdate(event, videoName, videoProperties?.metadata?.title, videoProperties?.metadata?.thumbnail, videoProperties?.metadata?.category, videoProperties?.metadata?.views)
                                }
                            />
                        </Grid>
                    </Modal>
                </Grid>
            </>
        );
    }
);

export default VideoCarouselSwiper;
