export default [
    {
        title: "Introduction to SEM",
        gif: require('./images/gif/book.gif').default,
        content: "SEM is a digital tool that helps Sales Force make smarter decisions using real-time data analytics and customer insights. To start, define sales goals and leverage SEM's features for decision-making.",
        ref: "/articles/intro-to-sem",
    },
    {
        title: "SEM Accomplishments",
        gif: require('./images/gif/package.gif').default,
        content: "Here are the accomplishments of SEM: optimized sales performance, better outcomes, smarter decision-making through real-time data analytics and customer insights.",
        ref: "/articles/sem-accomplishments",
    },
    {
        title: "Targeted Sales Activities",
        gif: require('./images/gif/trophy.gif').default,
        content: "Next best actions for our Sales Reps, from millions of transactions to one valuable specific action to help our customers grow",
        ref: "/articles/tsa",
    },
    // {
    //     title: "Support Team",
    //     gif: require('./images/gif/graph.gif').default,
    //     content: "All questions about SEM that are not Incidents or Change Requests should be asked via an RFI Service Now.",
    //     ref: "https://nextgen.service-now.com/",
    // },
]