import React from 'react';
import Internal from '../components/Pages/Internal';
import Document from '../components/Pages/Document';

export interface LandingPageProps {
  location: Location;
}

export default function LandingPage(props: LandingPageProps) {
  const isInternal = props.location.hostname.includes("internal");

  return isInternal ? <Internal /> : <Document />;
}